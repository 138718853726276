import React from "react"
import Seo from "../components/Seo"

const FourOhFour = () => {
  return (
    <>
      <Seo title="Sorry! Page missing!" />
      <section className="container flex flex-col gap-8 p-8 lg:flex-row lg:gap-40">

        <div class="flex flex-col gap-8 w-full lg:w-1/3">
        <h1 className="">Sorry, this page does not exist.</h1>
        </div>

      </section>
    </>
  )
}

export default FourOhFour
